import { Component, OnInit } from '@angular/core';

import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { FirebaseAuthService } from '../services/firebase-auth.service';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss'],
})

/**
 * Signup Component
 */
export class SignupComponent implements OnInit {
  passTextType!: boolean;
  fieldTextType!: boolean;
  //  Validation form
  validationform!: UntypedFormGroup;
  submit!: boolean;
  formsubmit!: boolean;

  signupCPassfield!: boolean;
  loginPassfield!: boolean;
  signupPassfield!: boolean;

  user: any;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private firebaseAuthUserService: FirebaseAuthService
  ) {
    this.firebaseAuthUserService.currentuser$.subscribe((user) => {
      this.user = user;
    });
  }

  saveUserInfo(userInfo: any) {
    if (userInfo) {
      userInfo.role = 'user';
      let model = { ...this.user, ...userInfo };
      delete model.authorized;
      delete model.unregistered;
      this.firebaseAuthUserService.createUser(model).then((res) => {
        console.log(res);
      });
    }
  }

  /**
   * Password Hide/Show
   */
  toggleLoginPassField() {
    this.loginPassfield = !this.loginPassfield;
  }

  /**
   * Password Hide/Show
   */
  toggleSignUpPassField() {
    this.signupPassfield = !this.signupPassfield;
  }

  /**
   * Password Hide/Show
   */
  toggleSignUpCPassField() {
    this.signupCPassfield = !this.signupCPassfield;
  }

  ngOnInit(): void {
    /**
     * Bootstrap validation form data
     */
    this.validationform = this.formBuilder.group({
      name: ['', [Validators.required]],
      email: ['', [Validators.required]],
      password: ['', [Validators.required]],
    });

    // Header Class Added
    document.body.classList.add('bg-secondary');
  }

  /**
   * Password Hide/Show
   */
  togglePassFieldTextType() {
    this.passTextType = !this.passTextType;
  }

  /**
   * Password Hide/Show
   */
  toggleFieldTextType() {
    this.fieldTextType = !this.fieldTextType;
  }

  /**
   * Bootsrap validation form submit method
   */
  validSubmit() {
    this.submit = true;
  }

  /**
   * Returns form
   */
  get form() {
    return this.validationform.controls;
  }
}
