import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';

@Component({
  selector: 'app-complete-registration',
  templateUrl: './complete-registration.component.html',
  styleUrls: ['./complete-registration.component.scss'],
})
export class CompleteRegistrationComponent implements OnInit {
  @Input()
  user: any;

  @Output()
  userInfo = new EventEmitter<any>();

  validationform!: UntypedFormGroup;
  submit!: boolean;
  formsubmit!: boolean;
  message: any = {};

  get form() {
    return this.validationform.controls;
  }

  isEligibleForSubmit() {
    let type = this.form['type'].value;
    let agreeTerms = this.form['agreeTerms'].value;
    if (type === 'jobseekers') {
      return this.form['mobile'].value && agreeTerms;
    } else {
      return (
        this.form['mobile'].value &&
        this.form['ename'].value &&
        this.form['pincode'].value &&
        this.form['location'].value &&
        agreeTerms &&
        !this.validationform.invalid
      );
    }
  }

  validSubmit() {
    this.submit = true;
    let type = this.form['type'].value;
    if (this.isEligibleForSubmit()) {
      if (type === 'jobseekers') {
        let formData = {
          type: this.form['type'].value,
          mobile: this.form['mobile'].value,
        };
        this.userInfo.emit(formData);
      } else {
        let formData = {
          mobile: this.form['mobile'].value,
          type: this.form['type'].value,
          employerName: this.form['ename'].value,
          pincode: this.form['pincode'].value,
          location: this.form['location'].value,
        };
        this.userInfo.emit(formData);
      }
    } else {
    }
  }

  constructor(private formBuilder: UntypedFormBuilder) {}
  ngOnInit(): void {
    this.validationform = this.formBuilder.group({
      mobile: [
        '',
        [
          Validators.pattern('^[0-9]*$'),
          Validators.minLength(10),
          Validators.maxLength(10),
        ],
      ],
      ename: ['', [Validators.required]],
      location: ['', [Validators.required]],
      pincode: ['', [Validators.required]],
      agreeTerms: ['', [Validators.required]],
      type: ['', [Validators.required]],
    });
  }
}
