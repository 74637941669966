import { Component, OnInit } from '@angular/core';

import { category, steps, promotion, jobCity, reviews } from './employer.model';
import {
  categoryData,
  stepsData,
  promotionData,
  jobCityData,
  reviewsData,
} from '../employer-home/data';

@Component({
  selector: 'app-employer-home',
  templateUrl: './employer-home.component.html',
  styleUrls: ['./employer-home.component.scss'],
})
export class EmployerHomeComponent implements OnInit {
  categoryData!: category[];
  stepsData!: steps[];
  promotionData!: promotion[];
  jobCityData!: jobCity[];
  reviewsData!: reviews[];

  constructor() {}

  ngOnInit(): void {
    // Chat Data Get Function
    this._fetchData();

    // Category Select data
    document
      .getElementById('category-content')
      ?.addEventListener('click', function (e) {
        const input = e.target as HTMLElement;
        const category = document.querySelector('.category') as HTMLElement;
        category.innerText = input.innerText;
      });

    // Location Select data
    document
      .getElementById('location-content')
      ?.addEventListener('click', function (e) {
        const input = e.target as HTMLElement;
        const location = document.querySelector('.location') as HTMLElement;
        location.innerText = input.innerText;
      });
  }

  // Chat Data Fetch
  private _fetchData() {
    this.categoryData = categoryData;
    this.stepsData = stepsData;
    this.promotionData = promotionData;
    this.jobCityData = jobCityData;
    this.reviewsData = reviewsData;
  }

  /**
   * Swiper setting
   */
  jobCity = {
    initialSlide: 0,
    slidesPerView: 1,
    spaceBetween: 25,
    navigation: true,
    loop: true,
    breakpoints: {
      768: {
        slidesPerView: 2,
      },
      1200: {
        slidesPerView: 5,
      },
    },
  };

  /**
   * Swiper setting
   */
  reviews = {
    initialSlide: 0,
    slidesPerView: 1,
    navigation: true,
    loop: true,
  };
}
