import { MenuItem } from './menu.model';

export const MENU: MenuItem[] = [
  {
    id: 1,
    label: 'Home',
    link: '/home',
  },
  {
    id: 4,
    label: 'Jobs',
    link: '/jobs/list',
  },
  {
    id: 21,
    label: 'Support',
    subItems: [
      {
        id: 22,
        label: 'About',
        link: '/support/about',
        parentId: 21,
      },
      {
        id: 26,
        label: 'Contacts',
        link: '/support/contacts',
        parentId: 21,
      },
      {
        id: 27,
        label: 'Help Center',
        parentId: 21,
        link: '/support/help-center',
      },
    ],
  },
];
