export const environment = {
  production: false,
  firebaseConfig: {
    apiKey: "AIzaSyCA_xKXjHsOXLibBq4_kYfrRGwgRLXl-Zk",
    authDomain: "talentforce-dev.firebaseapp.com",
    projectId: "talentforce-dev",
    storageBucket: "talentforce-dev.appspot.com",
    messagingSenderId: "813022734959",
    appId: "1:813022734959:web:7d74d56203d0faf89afb9a",
    measurementId: "G-Z46HZVKW44",
  },
};
