import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { PagesModule } from './pages/pages.module';
import { SharedModule } from './shared/shared.module';
import { environment } from '../environments/environment';

import { AngularFireModule, FIREBASE_OPTIONS } from '@angular/fire/compat';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { AngularFirestoreModule } from '@angular/fire/compat/firestore';
import { TtCommonLibModule } from 'tt-common-lib';
import { HttpClientModule } from '@angular/common/http';
import { FirebaseAuthService } from './auth/services/firebase-auth.service';
import { BrandingService } from 'tt-branding-lib';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    PagesModule,
    SharedModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFireAuthModule,
    AngularFirestoreModule,
    TtCommonLibModule,
    HttpClientModule,
    NgbModule,
    FormsModule,
  ],
  providers: [
    FirebaseAuthService,
    {
      provide: APP_INITIALIZER,
      useFactory: (config: BrandingService) => () => config.load(),
      deps: [BrandingService],
      multi: true,
    },
    { provide: FIREBASE_OPTIONS, useValue: environment.firebaseConfig },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
