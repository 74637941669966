import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, of, switchMap } from 'rxjs';
import { BrandingService } from 'tt-branding-lib';
import { AuthUserService } from './auth-user.service';

@Injectable({
  providedIn: 'root',
})
export class FirebaseAuthService {
  config: any;
  private _user = new BehaviorSubject<any>({});
  public readonly currentuser$ = this._user.asObservable();

  constructor(
    private afAuth: AngularFireAuth,
    private branding: BrandingService,
    public router: Router,
    public route: ActivatedRoute,
    private userService: AuthUserService
  ) {
    this.branding.config$.subscribe((data) => (this.config = data));
    this.loginWithBasicAuthentication();
  }

  private getUserInformation(userInfo: any): any {
    return (
      (userInfo && {
        id: userInfo.uid,
        uid: userInfo.uid,
        name: userInfo.displayName || '',
        email: userInfo.email || '',
        emailVerified: userInfo.emailVerified,
        photoURL: userInfo.photoURL || '',
        mobile: userInfo.phoneNumber || '',
        created: new Date().getTime(),
      }) ||
      {}
    );
  }

  logout() {
    this.afAuth.signOut().then(() => {
      this._user.next({});
      this.router.navigateByUrl('login');
    });
  }

  createUser(model: any) {
    return this.userService.createUser(model);
  }

  private setUserinfo(user: any) {
    this.userService.getUserValue(user.uid).then((userdata) => {
      const roles = this.config?.auth?.roles;
      //Is register user in system
      if (userdata && userdata?.uid) {
        //Is admin user
        if (roles.includes('all') || roles.includes(userdata.role)) {
          user.unregistered = false;
          user.authorized = true;
          user = { ...userdata, ...user };
          this._user.next(user);
        } else {
          //Invalid user and sending to register account
          user.unregistered = false;
          user.authorized = false;
          this._user.next(user);
        }
      } else {
        const createUserAllowed = this.config?.auth?.createUser;
        if (createUserAllowed) {
          user.unregistered = false;
          user.authorized = true;
          this.createUser(user).then(() => this._user.next(user));
        } else {
          if (roles.includes('all')) {
            //Should have valid user
            user.unregistered = true;
            user.authorized = false;
            this._user.next(user);
          } else {
            // Since no roles defined - marking it as authorized
            user.authorized = true;
            user.unregistered = false;
            this._user.next(user);
          }
        }
      }
    });
  }

  loginWithBasicAuthentication() {
    this.afAuth.user.subscribe((userInfo) => {
      //Check if we have user loggin or not
      if (userInfo && userInfo?.uid) {
        //if we find value user information
        let user = this.getUserInformation(userInfo);
        if (
          this.config?.auth?.type === 'firebaseui' ||
          this.config?.auth?.type === 'basicauth'
        ) {
          this.setUserinfo(user);
        }
      } else {
        //user logout
        this._user.next({});
      }
    });
  }

  loginWithToken() {
    this.afAuth.user.subscribe((userInfo) => {
      //Check if we have user loggin or not
      if (userInfo && userInfo?.uid) {
        //if we find value user information
        let user = this.getUserInformation(userInfo);
        if (
          this.config?.auth?.type === 'firebaseui' ||
          this.config?.auth?.type === 'basicauth'
        ) {
          this.setUserinfo(user);
        }
      } else {
        //user logout
        this._user.next({});
      }
    });
  }
}
