<!-- Navbar-->
<header
  class="navbar navbar-expand-lg navbar-dark bg-dark fixed-top"
  data-scroll-header
  (window:scroll)="windowScroll()"
>
  <div class="container">
    <button class="navbar-toggler" type="button" (click)="toggleMobileMenu()">
      <span class="navbar-toggler-icon"></span>
    </button>
    <a class="navbar-brand me-3 me-xl-4" routerLink=""
      ><img
        class="d-block"
        src="assets/img/logo/logo-light.svg"
        width="116"
        alt="Finder"
    /></a>

    <a
      *ngIf="user?.uid"
      class="btn btn-link btn-light btn-sm d-none d-lg-block order-lg-3"
      routerLink="/account/profile"
      data-bs-toggle="modal"
    >
      <i class="fi-user me-2"></i>My Account
    </a>

    <a
      *ngIf="!user?.uid"
      class="btn btn-link btn-light btn-sm d-none d-lg-block order-lg-3"
      routerLink="/login"
      data-bs-toggle="modal"
    >
      <i class="fi-user me-2"></i>Sign in
    </a>
    <a
      class="btn btn-primary btn-sm rounded-pill ms-2 order-lg-3"
      routerLink="/account/resume1"
      ><i class="fi-plus me-2"></i>Post Job</a
    >

    <div class="collapse navbar-collapse order-lg-2" id="navbarNav">
      <ul class="navbar-nav navbar-nav-scroll" style="max-height: 35rem">
        <ng-container *ngFor="let item of menuItems">
          <li class="nav-item dropdown">
            <a
              class="nav-link"
              *ngIf="!item.subItems"
              (click)="onMenuClick($event); toggleMobileMenu()"
              id="topnav-components"
              routerLink="{{ item.link }}"
              role="button"
            >
              {{ item.label }}
            </a>

            <a
              *ngIf="item.subItems"
              class="nav-link dropdown-toggle"
              (click)="onMenuClick($event)"
              id="topnav-components"
              role="button"
            >
              {{ item.label }}
            </a>

            <ul
              class="dropdown-menu dropdown-menu-dark"
              aria-labelledby="topnav-dashboard"
              *ngIf="hasItems(item)"
            >
              <ng-template
                ngFor
                let-i="index"
                let-subitem
                [ngForOf]="item.subItems"
              >
                <a
                  class="col dropdown-item side-nav-link-ref"
                  *ngIf="!hasItems(subitem)"
                  routerLink="{{ subitem.link }}"
                  (click)="toggleMobileMenu()"
                  routerLinkActive="active"
                  >{{ subitem.label }}</a
                >

                <div class="dropdown" *ngIf="hasItems(subitem)">
                  <a
                    class="dropdown-item dropdown-toggle"
                    href="javascript:void(0);"
                    (click)="onMenuClick($event)"
                    >{{ subitem.label }}
                    <div class="arrow-down"></div>
                  </a>
                  <ul class="dropdown-menu dropdown-menu-dark">
                    <ng-template
                      ngFor
                      let-subSubitem
                      [ngForOf]="subitem.subItems"
                    >
                      <a
                        class="dropdown-item"
                        *ngIf="!hasItems(subSubitem)"
                        routerLink="{{ subSubitem.link }}"
                        (click)="toggleMobileMenu()"
                        routerLinkActive="active"
                        >{{ subSubitem.label }}</a
                      >
                    </ng-template>
                  </ul>
                </div>
              </ng-template>
            </ul>
          </li>
        </ng-container>
      </ul>
    </div>
  </div>
</header>
