<div>
  <div class="px-2 pt-2 pb-4 px-sm-5 pb-sm-5 pt-md-5">
    <h4 class="text-center h4 my-2">Welcome <br />{{ user?.name }}</h4>
  </div>

  <div>
    <h5>Complete Registration</h5>
  </div>

  <form
    class="needs-validation"
    novalidate
    (ngSubmit)="validSubmit()"
    [formGroup]="validationform"
  >
    <div class="mb-4">
      <div>
        <label class="form-label mb-2" for="signin-email">User</label>
      </div>
      <div
        class="btn-group"
        type="group"
        aria-label="Radio Buttons with Bootstrap 5"
      >
        <input
          type="radio"
          formControlName="type"
          value="jobseekers"
          class="btn-check"
          id="typeBtnRadio1"
          autocomplete="off"
        />
        <label class="btn btn-outline-primary" for="typeBtnRadio1"
          >I am job seeker
        </label>

        <input
          type="radio"
          formControlName="type"
          value="employer"
          class="btn-check"
          id="typeBtnRadio2"
          autocomplete="off"
        />
        <label class="btn btn-outline-primary" for="typeBtnRadio2"
          >I am employer</label
        >
      </div>
    </div>

    <div class="mb-4">
      <div class="col-sm-12 mb-4">
        <label class="form-label" for="em-company">Mobile Number</label>
        <input
          class="form-control"
          formControlName="mobile"
          type="text"
          id="em-mobile"
          placeholder="Enter Mobile Number"
          required
          [ngClass]="{
            'is-invalid': formsubmit && form['mobile'].errors
          }"
        />
        <div class="invalid-feedback">Please enter you Mobile Number.</div>
      </div>
    </div>

    <div class="mb-4" *ngIf="form['type'].value === 'employer'">
      <div class="col-sm-12 mb-4">
        <label class="form-label" for="em-company">Employer name</label>
        <input
          class="form-control"
          formControlName="ename"
          type="text"
          id="em-company"
          placeholder="Enter Employer name"
          required
          [ngClass]="{
            'is-invalid': formsubmit && form['ename'].errors
          }"
        />
        <div class="invalid-feedback">Please enter you Employer name.</div>
      </div>
      <div class="col-sm-12 mb-4">
        <label class="form-label" for="em-location">Main office location</label>
        <input
          class="form-control"
          type="text"
          id="em-location"
          formControlName="location"
          placeholder="Location"
          required
          [ngClass]="{
            'is-invalid': formsubmit && form['location'].errors
          }"
        />
        <div class="invalid-feedback">Please enter you location.</div>
      </div>
      <div class="col-sm-12 mb-4">
        <label class="form-label" for="em-pincode">Pincode</label>
        <input
          class="form-control"
          type="text"
          id="em-pincode"
          formControlName="pincode"
          placeholder="Pincode"
          required
          [ngClass]="{
            'is-invalid': formsubmit && form['pincode'].errors
          }"
        />
        <div class="invalid-feedback">Please enter you Pincode.</div>
      </div>
    </div>

    <div class="py-md-2">
      <div class="form-check">
        <input
          class="form-check-input"
          type="checkbox"
          id="agree-to-terms"
          formControlName="agreeTerms"
          required
        />
        <label class="form-check-label" for="agree-to-terms"
          >By joining, I agree to the
          <a href="/terms" target="_blank">Terms of use</a> and
          <a href="/policy" target="_blank">Privacy policy</a></label
        >
      </div>
      <button
        [disabled]="!isEligibleForSubmit()"
        class="btn btn-primary btn-lg w-100 my-2"
        type="submit"
      >
        Complete Sign up
      </button>
    </div>
  </form>

  <div class="mt-sm-4 py-md-3 text-center">
    Already have an account? <a routerLink="/auth/signin">Sign in</a>
  </div>
</div>
