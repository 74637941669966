import { Component, OnInit } from '@angular/core';

import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { GoogleAuthProvider } from 'firebase/auth';
import { LoginService } from 'src/app/services/login.service';

@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.scss'],
})

/**
 * Signin Component
 */
export class SigninComponent implements OnInit {
  fieldTextType!: boolean;
  //  Validation form
  validationform!: UntypedFormGroup;
  submit!: boolean;
  formsubmit!: boolean;
  message: any = {};
  returnUrl!: string;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private loginService: LoginService,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/home';
    /**
     * Bootstrap validation form data
     */
    this.validationform = this.formBuilder.group({
      email: [
        '',
        [
          Validators.required,
          Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$'),
        ],
      ],
      password: ['', [Validators.required]],
    });

    // Header Class Added
    document.body.classList.add('bg-secondary');
  }

  /**
   * Password Hide/Show
   */
  toggleFieldTextType() {
    this.fieldTextType = !this.fieldTextType;
  }

  /**
   * Bootsrap validation form submit method
   */
  validSubmit() {
    this.submit = true;
    if (!this.validationform?.invalid) {
      const email = this.validationform.value?.email;
      const password = this.validationform.value?.email;
      this.loginService
        .LoginWithEmailAndPassword(email, password)
        .catch((err) => this.handleError(err))
        .then((respone) => {
          if (respone) {
            let user = respone.user;
            if (user?.uid && this.returnUrl) {
              this.router.navigateByUrl(this.returnUrl);
            } else {
              this.router.navigateByUrl('/home');
            }
          }
        });
    }
  }

  signInWithGoogle() {
    this.loginService.loginWithGoogle().then((res) => {
      if (res) {
        let user = res.user;
        if (user?.uid && this.returnUrl) {
          this.router.navigateByUrl(this.returnUrl);
        } else {
          this.router.navigateByUrl('/home');
        }
      }
    });
  }

  handleError(err: any) {
    switch (err.code) {
      case 'auth/wrong-password':
        this.message = {
          error: 'You have entered an invalid username or password',
        };
        break;
      case 'auth/user-not-found':
        this.message = {
          error:
            "The email address you entered isn't connected to any account.",
        };
        break;
      default:
        this.message = { error: 'An error occured, Please try again later.' };
    }
  }

  /**
   * Returns form
   */
  get form() {
    return this.validationform.controls;
  }
}
