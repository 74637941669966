import { Injectable } from '@angular/core';
import {
  AngularFirestore,
  AngularFirestoreCollection,
  AngularFirestoreDocument,
} from '@angular/fire/compat/firestore';
import { resolve } from 'dns';
import { BrandingService } from 'tt-branding-lib';

@Injectable({
  providedIn: 'root',
})
export class AuthUserService {
  basePath: string = '';
  config: any;

  constructor(
    private store: AngularFirestore,
    private branding: BrandingService
  ) {
    this.branding.config$.subscribe((data) => (this.config = data));
    this.basePath = this.config?.app?.path;
  }

  public createUser(model: any) {
    if (model.emailVerified) return this.registerUser(model);
    else return this.registerPreUser(model);
  }
  getAllUser() {
    return this.store.collection(this.basePath + '/users').valueChanges();
  }
  private async registerUser(user: any) {
    return new Promise((resolve) => {
      this.getUser(user.uid).subscribe(async (data: any) => {
        if (data) resolve(false);
        else {
          await this.store
            .collection(this.basePath + '/users')
            .doc(user.uid)
            .set(user)
            .then(() => resolve(true));
        }
      });
    });
  }
  private async registerPreUser(user: any) {
    return new Promise((resolve) => {
      this.getUser(user.uid).subscribe(async (data: any) => {
        if (data) resolve(false);
        else {
          await this.store
            .collection(this.basePath + '/pre-users')
            .doc(user.uid)
            .set(user)
            .then(() => resolve(true));
        }
      });
    });
  }

  updateuserPartial(user: any) {
    return new Promise((resolve) => {
      this.getUserValue(user.uid).then((userFromDB) => {
        let userInfo = { ...userFromDB, ...user };
        this.updateUser(userInfo).then(() => {
          resolve(true);
        });
      });
    });
  }

  updateuserLoginInfo(user: any) {
    return new Promise((resolve) => {
      this.getUserValue(user.uid).then((userFromDB) => {
        let userInfo = { ...userFromDB, ...user };
        this.updateUser(userInfo).then(() => {
          resolve(true);
        });
      });
    });
  }

  private updateUser(user: any) {
    return this.store
      .collection(this.basePath + '/users')
      .doc(user.uid)
      .set(user, { merge: true });
  }

  public getUser(uid: string) {
    return (
      this.store
        .collection(this.basePath + '/users')
        .doc(uid) as AngularFirestoreDocument<any>
    ).valueChanges();
  }

  public getUserValue(uid: string): Promise<any> {
    const collectionRef: AngularFirestoreCollection<any> =
      this.store.collection(this.basePath + '/users');
    return new Promise((resolve) => {
      collectionRef
        .doc(uid)
        .get()
        .subscribe((data) => {
          data.exists ? resolve(data.data()) : resolve({});
        });
    });
  }
}
