<div class="min-vh-100 d-flex align-items-center my-5 justify-content-center">
  <div class="container-fluid">
    <div class="card card-body mx-auto" style="max-width: 940px">
      <a
        class="position-absolute top-0 end-0 nav-link fs-sm py-1 px-2 mt-3 me-3"
        routerLink=""
        ><i class="fi-arrow-long-left fs-base me-2"></i>Go back</a
      >

      <div class="row mx-0 align-items-center">
        <ng-template #completeSignUp>
          <div class="col-md-6 px-2 pt-2 pb-4 px-sm-5 pb-sm-5 pt-md-5">
            <app-complete-registration
              [user]="user"
              (userInfo)="saveUserInfo($event)"
            ></app-complete-registration>
          </div>
        </ng-template>

        <div
          class="col-md-6 px-2 pt-2 pb-4 px-sm-5 pb-sm-5 pt-md-5"
          *ngIf="!user?.uid; else completeSignUp"
        >
          <a class="btn btn-outline-info w-100 mb-3" href="javascript:void(0);"
            ><i class="fi-google fs-lg me-1"></i>Sign in with Google</a
          >

          <!--<a class="btn btn-outline-info w-100 mb-3" href="javascript:void(0);"
            ><i class="fi-facebook fs-lg me-1"></i>Sign in with Facebook</a
          >-->
          <div class="d-flex align-items-center py-3 mb-3">
            <hr class="w-100" />
            <div class="px-3">Or</div>
            <hr class="w-100" />
          </div>
          <form
            class="needs-validation"
            novalidate
            (ngSubmit)="validSubmit()"
            [formGroup]="validationform"
          >
            <div class="mb-4">
              <label class="form-label" for="signup-name">Full name</label>
              <input
                class="form-control"
                type="text"
                id="signup-name"
                placeholder="Enter your full name"
                required
                formControlName="name"
                [ngClass]="{ 'is-invalid': submit && form['name'].errors }"
              />
              <div class="invalid-feedback">Please enter you full name.</div>
            </div>
            <div class="mb-4">
              <label class="form-label" for="signup-email">Email address</label>
              <input
                class="form-control"
                type="email"
                id="signup-email"
                placeholder="Enter your email"
                required
                formControlName="email"
                [ngClass]="{ 'is-invalid': submit && form['email'].errors }"
              />
              <div class="invalid-feedback">Please enter you email.</div>
            </div>
            <div class="mb-4">
              <label class="form-label" for="signup-password"
                >Password
                <span class="fs-sm text-muted">min. 8 char</span></label
              >
              <div class="password-toggle">
                <input
                  class="form-control"
                  [type]="passTextType ? 'text' : 'password'"
                  id="signup-password"
                  minlength="8"
                  required
                  formControlName="password"
                  [ngClass]="{
                    'is-invalid': submit && form['password'].errors
                  }"
                />
                <label
                  class="password-toggle-btn"
                  aria-label="Show/hide password"
                >
                  <input class="password-toggle-check" type="checkbox" /><span
                    class="password-toggle-indicator"
                    [ngClass]="{
                      'mdi-eye-off-outline': !passTextType,
                      'mdi-eye-outline': passTextType
                    }"
                    (click)="togglePassFieldTextType()"
                  ></span>
                </label>
              </div>
            </div>
            <div class="mb-4">
              <label class="form-label" for="signup-password-confirm"
                >Confirm password</label
              >
              <div class="password-toggle">
                <input
                  class="form-control"
                  [type]="fieldTextType ? 'text' : 'password'"
                  id="signup-password-confirm"
                  minlength="8"
                  required
                />
                <label
                  class="password-toggle-btn"
                  aria-label="Show/hide password"
                >
                  <input class="password-toggle-check" type="checkbox" /><span
                    class="password-toggle-indicator"
                    [ngClass]="{
                      'mdi-eye-off-outline': !fieldTextType,
                      'mdi-eye-outline': fieldTextType
                    }"
                    (click)="toggleFieldTextType()"
                  ></span>
                </label>
              </div>
            </div>
            <div class="form-check mb-4">
              <input
                class="form-check-input"
                type="checkbox"
                id="agree-to-terms"
                required
              />
              <label class="form-check-label" for="agree-to-terms"
                >By joining, I agree to the
                <a href="javascript:void(0);">Terms of use</a> and
                <a href="javascript:void(0);">Privacy policy</a></label
              >
            </div>
            <button class="btn btn-primary btn-lg w-100" type="submit">
              Sign up
            </button>
          </form>
        </div>

        <div class="col-md-6 border-start-md p-2 p-sm-5">
          <h2 class="h3 mb-4 mb-sm-5">
            Join Finder.<br />Get premium benefits:
          </h2>
          <ul class="list-unstyled mb-4 mb-sm-5">
            <li class="d-flex mb-2">
              <i class="fi-check-circle text-primary mt-1 me-2"></i
              ><span>Add and promote your listings</span>
            </li>
            <li class="d-flex mb-2">
              <i class="fi-check-circle text-primary mt-1 me-2"></i
              ><span>Easily manage your wishlist</span>
            </li>
            <li class="d-flex mb-0">
              <i class="fi-check-circle text-primary mt-1 me-2"></i
              ><span>Leave reviews</span>
            </li>
          </ul>
          <img
            class="d-block mx-auto"
            src="assets/img/signin-modal/signup.svg"
            width="344"
            alt="Illustartion"
          />
        </div>
      </div>
    </div>
  </div>
</div>
